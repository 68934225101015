// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostStartRequest {
  region: 'eu' | 'us';
  /** The user email or organization name that the user entered. */
  query: string;
  /** A URL to where the user should end up when signed in. */
  continue?: string;
}

export function postStartRequest(requestParams: PostStartRequest): Request {
  const data = {
    query: toSnakeKeys(requestParams.query),
    continue: toSnakeKeys(requestParams.continue),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/saml/start`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
