import * as React from 'react';
import { Button, Copy } from '@mentimeter/ragnar-ui';
import { ClipboardIcon } from '@mentimeter/ragnar-visuals';

interface SSOErrorMessageDetails {
  errorCode?: string | undefined;
  requestId?: string | undefined;
}

export function SSOErrorMessageDetailsCopyButton({
  errorCode,
  requestId,
}: SSOErrorMessageDetails) {
  return (
    <Copy
      justifyContent="center"
      alignItems="center"
      id="copy-moderation-url-button"
      src={JSON.stringify({
        'Error code': errorCode,
        'Request ID': requestId,
      })}
    >
      {({ copy }) => (
        <Button
          variant="outline"
          iconLeading={ClipboardIcon}
          size="compact"
          onClick={() => {
            copy();
          }}
        >
          Copy
        </Button>
      )}
    </Copy>
  );
}
