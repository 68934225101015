export const ssoErrorCodes = {
  IDENTITY_PROVIDER_NOT_FOUND: 'identity_provider_not_found',
  INSUFFICIENT_WORKSPACE_PLAN: 'insufficient_workspace_plan',
  INVALID_CONFIRMATION_TOKEN: 'invalid_confirmation_token',
  INVALID_SAML_METADATA: 'invalid_saml_metadata',
  INVALID_SAML_RESPONSE: 'invalid_saml_response',
  LOGIN_TIMEOUT: 'login_timeout',
  MEMBER_OF_OTHER_WORKSPACE: 'member_of_other_workspace',
  MISSING_SAML_RESPONSE: 'missing_saml_response',
  USER_ACCOUNT_DEACTIVATED: 'user_account_deactivated',
  SERVICE_UNAVAILABLE: 'service_unavailable',
  TENANT_RESTRICTED_WORKSPACE: 'tenant_restricted_workspace',
  WORKSPACE_LICENSES_EXHAUSTED: 'workspace_licenses_exhausted',
  WORKSPACE_INVITATION_NOT_FOUND: 'workspace_invitation_not_found',
  WORKSPACE_NOT_FOUND: 'workspace_not_found',
  DOMAIN_NOT_FOUND: 'domain_not_found',
  WORKSPACE_CONFLICT: 'workspace_conflict',
};
