// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface PostStartResponse {
  /** Identity Provider URL, to which the user should be redirected for authentication. */
  redirect: string;
}

export async function postStartResponse(
  response: Response,
): Promise<PostStartResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostStartResponse;
}
