import { trackUser } from '@api/tracking/client';
import { gtmTrack } from '@mentimeter/google-tracking';
import {
  type WorkspaceRole,
  type TrackingContext,
  tracking,
} from '@mentimeter/http-clients';

export const trackViewedPage = (
  pageName: string = typeof window === 'undefined'
    ? ''
    : window.location.pathname,
  properties?: Record<string, string | number | null | undefined>,
) => {
  trackUser({
    event: 'Viewed page',
    properties: properties
      ? { Page: pageName, ...properties }
      : { Page: pageName },
  });
};

export const trackViewedFolder = (
  context: TrackingContext,
  presentationsInFolder: number,
  foldersInFolder: number,
  role: 'Owner' | 'Collaborator',
  currentPlan: string,
  folderId: number,
  inUserSharedFolder: boolean,
) => {
  trackUser({
    event: 'Viewed folder',
    properties: {
      context,
      'presentations in folder': presentationsInFolder,
      'folders in folder': foldersInFolder,
      role,
      'current plan': currentPlan,
      'folder id': folderId,
      'user shared folder': inUserSharedFolder,
    },
  });
};

export const trackChangedWorkspaceRole = (
  from: WorkspaceRole,
  to: WorkspaceRole,
  context: TrackingContext,
) =>
  trackUser({
    event: 'Changed team member role',
    properties: {
      context,
      to,
      from,
    },
  });

export const trackClickedInviteMembersButton = ({
  context,
  activeTeamMembers,
  currentPlan,
  role,
}: {
  context: string;
  activeTeamMembers: number;
  currentPlan: string;
  role: WorkspaceRole;
}) => {
  trackUser({
    event: 'Clicked invite members button',
    properties: {
      context,
      'workspace role': role,
      'active team members': activeTeamMembers,
      'current plan': currentPlan,
    },
  });
};

export const trackApprovedJoinRequest = (
  workspaceId: number,
  plan: string | undefined,
) => {
  trackUser({
    event: 'Approved join request',
    properties: {
      'workspace id': workspaceId,
      plan,
    },
  });
};

export const trackClickUpgrade = (payload: {
  event: string;
  properties: {
    context: string;
    plan?: string | null;
    'current plan'?: string;
    role?: string | null;
    'presentation owner'?: boolean;
    'shared presentation'?: boolean;
    'active team members'?: number;
  };
}) => {
  gtmTrack({
    event: 'clickInteraction',
    type: 'upgradeFromApp',
  });
  trackUser(payload);
};

export const trackClickedCancel = () => {
  trackUser({
    event: 'Clicked cancel upgrade modal',
    properties: {
      context: 'Workspace upgrade',
    },
  });
};

export const trackClickedContinueUpgradeModal = ({
  teamSize,
  teamMemberLicenses,
  additionalLicenses,
  totalLicenses,
}: {
  teamSize: number;
  teamMemberLicenses: number;
  additionalLicenses: number;
  totalLicenses: number;
}) => {
  trackUser({
    event: 'Clicked continue upgrade modal',
    properties: {
      context: 'Workspace upgrade',
      'current team size': teamSize,
      'team member licenses': teamMemberLicenses,
      'additional licenses': additionalLicenses,
      'total licenses': totalLicenses,
    },
  });
};

/** @deprecated All usages of this function are tracking the same thing as the trackViewedPage()
 * function above but with a different event name. Do NOT use it for new tracking
 * functionalities and use trackViewedPage() instead.
 * If you're already using this function, please consider refactoring to use the
 * trackViewedPage() after syncing with the consumer of the tracking data.
 **/
export const trackViewedPageWithProps = ({
  event,
  properties,
}: {
  event: string;
  properties: Record<string, string | number | null | undefined>;
}) => {
  trackUser({
    event,
    properties,
  });
};

export const trackTriedToLoginWithSSO = async (
  company: string,
  visitorToken: string | null,
  onError: (error: unknown) => void,
) => {
  if (visitorToken) {
    try {
      await tracking().trackVisitor(
        {
          event: 'Tried to login',
          properties: { company, context: 'SSO' },
        },
        visitorToken,
      );
    } catch (e) {
      onError(e);
    }
  }
};

export const trackClickedChangePaymentMethod = (context: string) => {
  trackUser({
    event: 'Clicked change payment method',
    properties: { context },
  });
};

export const trackClickedAutomaticRenewalToggle = (
  context: string,
  renewal: 'on' | 'off',
  reason?: string,
  otherFreeTextReason?: string,
) => {
  trackUser({
    event: 'Clicked automatic renewal toggle',
    properties: {
      context,
      renewal,
      reason,
      'other reason': otherFreeTextReason,
    },
  });
};
