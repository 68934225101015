import * as React from 'react';
import { Box, P } from '@mentimeter/ragnar-ui';
import { SSOHelpArticleLink, SSOErrorMessageDetailsCopyButton } from '.';

interface SSOErrorMessageDetails {
  errorCode?: string | undefined;
  requestId?: string | undefined;
}

export function SSOErrorMessageDetails({
  errorCode,
  requestId,
}: SSOErrorMessageDetails) {
  return errorCode || requestId ? (
    <Box flexDirection="column" alignItems="center">
      <Box
        flexDirection="column"
        justifyContent="space-between"
        alignItems="end"
        width="100%"
      >
        {errorCode ? (
          <Box flex="1 1 auto" width="100%">
            <P fontSize={1} mb={0} flex="1 1 auto" width="100%">
              Error code: <SSOHelpArticleLink>{errorCode}</SSOHelpArticleLink>
            </P>
          </Box>
        ) : (
          <Box flex="1 1 auto" width="100%" />
        )}
        {requestId && (
          <P fontSize={1} mb={0} width="100%">
            Request ID: {requestId}
          </P>
        )}
        {(errorCode || requestId) && (
          <SSOErrorMessageDetailsCopyButton
            errorCode={errorCode}
            requestId={requestId}
          />
        )}
      </Box>
    </Box>
  ) : null;
}
